@import '../../App.scss';

.balance_card {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: rem(12);
  padding: rem(32) rem(24);
  max-height: rem(138);
  min-height: rem(138);
  user-select: none;
  &:not(:last-child) {
    margin-bottom: rem(32);
  }
  &.equal {
    background-color: var(--primaryBlue);
  }
  &.minus {
    background-color: var(--red);
  }
  &.plus {
    background-color: var(--green);
  }
  &_left {
    display: flex;
    svg {
      margin-right: rem(24);
      path {
        fill: var(--white) !important;
      }
    }
  }
  &_right {
    &_title {
      // font-weight: 700;
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(20);
      // color: var(--modalTitle);
      color: var(--white);
    }
    .balance {
      display: flex;
      align-items: flex-start;
      margin: 0;
      .dollar_icon {
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(21);
        color: var(--white);
        // color: var(--dollarIcon);
        margin-right: rem(5);
        margin-top: rem(5);
      }
      .amount {
        font-weight: 700;
        font-size: rem(34);
        line-height: rem(44);
        color: var(--white);
        // color: var(--modalTitle);
      }
    }
  }
  .detailed {
    p {
      font-weight: 600;
      font-size: rem(34);
      line-height: rem(44);
      color: var(--white);
      margin: 0;
      &:not(:last-child) {
        margin-bottom: rem(8);
      }
      span {
        font-size: rem(16);
        line-height: rem(20);
      }
    }
  }
  .eye_icon {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    padding-left: rem(8);
  }
}

// 575px
@media (max-width: 575px) {
  .balance_card {
    padding: 2rem 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &_left {
      svg {
        margin-right: 1rem;
      }
    }
    &_right {
      .balance {
        .amount {
          font-size: rem(24);
          line-height: rem(28);
        }
      }
    }
    .detailed {
      p {
        font-size: rem(24);
        line-height: rem(28);
      }
    }
  }
}
