@import '../../App.scss';

.header {
  display: flex;
  align-items: center;
  height: rem(64);
  background: orangered;
  border-bottom: rem(1) solid var(--border);
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    font-weight: 600;
    font-size: rem(24);
    line-height: rem(29);
    color: var(--primaryBlue);
    margin: 0;
  }
  &_right {
    display: flex;
    align-items: center;
    .settings {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: rem(24);
    }
    .user {
      display: flex;
      align-items: center;
      cursor: pointer;
      .ava {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);
        background-color: var(--primaryBlue);
        border-radius: 50%;
        margin-right: rem(8);
        span {
          font-weight: 500;
          font-size: rem(18);
          line-height: rem(20);
          color: var(--white);
        }
      }
      p {
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(20);
        color: var(--modalTitle);
        margin: 0;
        margin-right: rem(4);
      }
    }
  }
}

.user_popover {
  padding-top: unset !important;
  width: rem(252);
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border: rem(1) solid var(--border);
    border-radius: rem(8) !important;
    .ant-popover-inner-content {
      padding: rem(10) rem(24);
      cursor: pointer;
      .user {
        display: flex;
        align-items: center;
        user-select: none;
        margin-bottom: 1rem;
        .ava {
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem(40);
          height: rem(40);
          background-color: var(--primaryBlue);
          border-radius: 50%;
          margin-right: rem(8);
          span {
            font-weight: 500;
            font-size: rem(18);
            line-height: rem(20);
            color: var(--white);
          }
        }
        p {
          font-weight: 500;
          font-size: rem(18);
          line-height: rem(20);
          color: var(--modalTitle);
          margin: 0;
        }
        svg {
          margin-left: auto;
        }
      }
      .logout {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(8) 0;
        p {
          font-weight: 500;
          font-size: rem(18);
          line-height: rem(20);
          color: var(--red);
          margin: 0;
          margin-right: rem(4);
        }
      }
    }
  }
}

// 575px
@media (max-width: 575px) {
  .header {
    margin-bottom: 1rem;
  }
}
