@import '../../App.scss';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/image/loginBg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  // background-color: var(--primaryBlue);
  .auth {
    border: rem(1) solid var(--border);
    border-radius: rem(8);
    padding: 1rem;
    box-shadow: 0 rem(8) rem(24) rem(-4) rgba(27, 46, 94, 0.08);
    background-color: var(--white);
    width: rem(300);
    .ant-form {
      #login {
        border-radius: rem(6) !important;
      }
      .ant-input-password {
        border-radius: rem(6) !important;
      }
      .common_btn {
        width: 100%;
      }
    }
  }
}
