@import '../../App.scss';

.payment_modal {
  .payment_form {
    .amount_cont_uzs {
      .currency_usd {
        width: rem(120);
      }
    }
    .amount_cont,
    .amount_cont_uzs {
      display: flex;
      .ant-form-item {
        &:first-child {
          flex: 1;
          .ant-row {
            flex: 1;
          }
        }
      }
      .ant-select {
        width: rem(96);
        &-selector {
          height: rem(56);
          border-color: var(--border);
          border-radius: rem(12);
          .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: rem(18);
            line-height: rem(24);
          }
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          background-color: initial !important;
          color: initial !important;
        }
      }
    }
  }
  .error_input {
    input {
      border-color: #ff4d4f !important;
      &:focus {
        box-shadow: 0 0 0 2px var(--ant-error-color-outline);
      }
    }
  }
  .ant-input-disabled {
    background-color: initial !important;
    color: initial !important;
  }
}
