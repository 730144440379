@import '../App.scss';

.custom_modal {
  width: rem(544) !important;
  .ant-modal-content {
    border-radius: rem(15);
    .ant-modal-body {
      padding: rem(24) rem(32);
    }
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    h1 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(32);
      color: var(--modalTitle);
      margin: 0;
    }
    .close_icon {
      display: flex;
      cursor: pointer;
    }
  }
  &_body {
    padding-bottom: rem(12);
    .ant-form {
      .ant-form-item-label {
        label {
          font-weight: 400;
          font-size: rem(18);
          line-height: rem(24);
          color: var(--modalTitle);
        }
      }
      input,
      textarea {
        border-radius: rem(12);
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(24);
        border-color: var(--border) !important;
      }
      input {
        height: rem(56);
      }
    }
  }
  &_footer {
    display: flex;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(56);
      width: 100%;
      border-radius: rem(50);
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(24);
      text-shadow: none;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &.cancel_btn {
        background-color: var(--cancelBtnBg);
      }
    }
  }
}
