@import '../../App.scss';

.client_modal {
  .custom_modal_body {
    .ant-select {
      &-selector {
        height: rem(56) !important;
        border-color: var(--border) !important;
        border-radius: rem(12) !important;
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: rem(18);
          line-height: rem(24);
        }
      }
    }
    .current_balance {
      display: flex;
      .ant-form-item {
        flex: 1;
        .ant-input-disabled {
          background-color: initial !important;
          color: initial !important;
        }
      }
    }
    .currency_cont {
      display: flex;
      .ant-form-item {
        flex: 1;
        &:first-child {
          margin-right: rem(16);
        }
      }
      .ant-input-disabled {
        background-color: initial !important;
        color: initial !important;
      }
    }
    .ant-select-disabled {
      .ant-select-selector {
        background-color: initial !important;
        color: initial !important;
      }
    }
  }
}
