@import '../../App.scss';

.product_modal {
  width: rem(1400) !important;
  &_body {
    margin-bottom: rem(8);
    .ant-form {
      display: flex;
      .ant-form-item {
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &.name {
          flex: 1;
          min-width: rem(180);
        }
        &.unit {
          min-width: rem(120);
        }
        &.quantity {
          min-width: rem(140);
        }
        &.price {
          min-width: rem(160);
        }
        &.overall {
          min-width: rem(120);
        }
        &.currency_usd {
          min-width: rem(120);
        }
        &.submit {
          display: flex;
          align-items: flex-end;
          button {
            width: rem(56);
            height: rem(56);
            border-radius: 50%;
            font-weight: 700;
            font-size: rem(24);
            line-height: rem(32);
            text-shadow: none;
          }
        }
        .ant-input-disabled {
          background-color: initial !important;
          color: initial !important;
        }
      }
      .ant-form-item-label {
        label {
          font-weight: 400;
          font-size: rem(18);
          line-height: rem(24);
          color: var(--modalTitle);
        }
      }
      input,
      textarea {
        border-radius: rem(12);
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(24);
        border-color: var(--border) !important;
      }
      input {
        height: rem(56);
      }
      .ant-select {
        width: rem(96);
        &-selector {
          height: rem(56) !important;
          border-color: var(--border) !important;
          border-radius: rem(12) !important;
          .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: rem(18);
            line-height: rem(24);
          }
        }
      }
    }
  }
  .custom_modal_footer {
    justify-content: flex-end;
    button {
      width: rem(232);
    }
  }
}

// 575px
@media (max-width: 575px) {
  .product_modal {
    &_body {
      overflow-y: auto;
    }
  }
}
