@import '../../App.scss';

.not_found {
  display: flex;
  background-color: var(--primaryHoverBlue);
  height: 100vh;
  h1 {
    flex: 1;
    text-align: center;
    color: var(--modalTitle);
    margin-top: 4rem;
  }
}
