@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@800;900&display=swap');

body {
  margin: 0 !important;
  padding: 0;
  font-family: 'DM Sans', sans-serif !important;
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primaryBlue);
    border-radius: 0.5rem;
  }
  &.ant-scrolling-effect {
    width: calc(100% - 0.2rem) !important;
    .header {
      width: calc(100% - 0.2rem) !important;
    }
  }
}

$BASE_BODY_FONT_SIZE: 16;
@function rem($pixels) {
  @return ($pixels/$BASE_BODY_FONT_SIZE) + rem;
}

.wrapper {
  max-width: rem(1920);
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
}

.common_btn.ant-btn {
  border-radius: rem(6) !important;
  height: rem(40) !important;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(24);
  text-shadow: none;
}

.ant-popover-inner {
  border-radius: rem(12) !important;
}

.df {
  display: flex;
}

.ai_c {
  align-items: center;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-c {
  justify-content: center;
}

.dn {
  display: none !important;
}

.p4 {
  padding: rem(4);
}

.p0 {
  padding: 0 !important;
}

.w300 {
  width: rem(300);
}

.ml-16 {
  margin-left: rem(16) !important;
}

.mr-16 {
  margin-right: rem(16) !important;
}

.m0 {
  margin: 0 !important;
}

.color_initial {
  color: initial;
}

.cur_p {
  cursor: pointer;
  user-select: none;
}

.ta_c {
  text-align: center;
}
