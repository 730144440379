@import '../../App.scss';

.detailed_client {
  display: flex;
  min-height: 100vh;
  padding-bottom: rem(20) !important;
  &_left {
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: rem(1) solid var(--border);
    flex: 1;
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0;
      .left {
        display: flex;
        align-items: center;
        .prev_icon {
          display: flex;
          width: rem(24);
          height: rem(24);
          margin-right: rem(9);
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        h1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 31px;
          color: var(--modalTitle);
          margin: 0;
        }
      }
      .right {
        display: flex;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(40);
          width: rem(220);
          // width: rem(129);
          border-radius: rem(6);
          padding: 0;
          font-weight: 500;
          font-size: rem(16);
          line-height: rem(24);
          text-shadow: none;
          &:not(:last-child) {
            margin-right: rem(16);
          }
          &.income {
            color: var(--green);
            border-color: var(--green);
          }
          &.outcome {
            color: var(--red);
            border-color: var(--red);
          }
          svg {
            margin-right: rem(8);
          }
        }
      }
    }
    .custom_table {
      .ant-table {
        &-tbody {
          .date {
            font-weight: 500;
            color: var(--dollarIcon);
          }
          .info {
            font-weight: 500;
            color: var(--modalTitle);
          }
          .payment {
            .cont {
              display: flex;
              align-items: center;
              font-weight: 500;
              margin: 0;
              color: var(--modalTitle);
              float: right;
              svg {
                margin-right: rem(8);
              }
              .dollar_icon {
                color: var(--dollarIcon);
              }
            }
          }
          .creator {
            font-weight: 500;
            color: var(--dollarIcon);
          }
          .deleted_status {
            font-weight: 600;
            font-size: rem(12);
            line-height: rem(16);
            color: var(--red);
            margin: 0;
          }
          .actions {
            display: flex;
          }
          .deleted_text {
            color: var(--deleted);
            svg {
              path {
                stroke: var(--deleted);
              }
            }
            .dollar_icon {
              color: var(--deleted) !important;
            }
            .amount {
              color: var(--deleted) !important;
            }
          }
          .selected_row {
            background-color: var(--primaryLightBlue);
            &:hover {
              .ant-table-cell {
                background-color: transparent;
              }
            }
            .deleted_text {
              color: var(--white);
              svg {
                path {
                  stroke: var(--white);
                }
              }
              .dollar_icon {
                color: var(--white) !important;
              }
              .amount {
                color: var(--white) !important;
              }
            }
            .date {
              color: var(--white);
            }
            .info {
              color: var(--white);
            }
            .payment {
              svg path {
                stroke: var(--white);
              }
              .dollar_icon {
                color: var(--white);
              }
              .amount {
                color: var(--white);
                color: var(--white);
              }
            }
            .creator {
              color: var(--white);
            }
            .actions {
              svg path {
                fill: var(--white);
              }
              .view {
                svg {
                  path {
                    &:first-child {
                      stroke: var(--white);
                    }
                    &:last-child {
                      stroke: var(--primaryLightBlue);
                    }
                  }
                }
              }
            }
            .deleted_status {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  &_right {
    min-width: rem(385);
    padding-top: rem(20);
    .title {
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--modalTitle);
      margin-bottom: rem(20);
      text-align: center;
    }
    .history_card {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      p {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(16);
        color: var(--modalTitle);
        margin-bottom: rem(2);
      }
      span {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(16);
        color: var(--dollarIcon);
      }
    }
  }
}

// 575px
@media (max-width: 575px) {
  .detailed_client {
    flex-direction: column-reverse;
    padding: 0 rem(12) !important;
    &_left {
      padding-right: 0;
      margin-right: 0;
      border-right: unset;
      &_header {
        flex-direction: column-reverse;
        margin: 0;
        margin-bottom: 1rem;
        .left {
          width: 100%;
          margin-top: 1rem;
        }
        .right {
          width: 100%;
          button {
            width: unset !important;
            flex: 1;
          }
        }
      }
      .ant-table-cell {
        padding: rem(12) rem(12) !important;
      }
    }
    &_right {
      padding-top: unset;
      min-width: unset !important;
      .history_card {
        margin-bottom: 1rem !important;
      }
    }
  }
}
