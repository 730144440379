@import '../../App.scss';

.clients {
  display: flex;
  min-height: 100vh;
  padding-bottom: rem(20) !important;
  &_left {
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: rem(1) solid var(--border);
    flex: 1;
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
      .ant-input-affix-wrapper {
        width: rem(505);
        height: rem(40);
        border-color: var(--border);
        border-radius: rem(8);
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(40);
        border-radius: rem(6);
        padding: 0;
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        text-shadow: none;
        &.create {
          width: rem(180);
        }
        &.excel {
          width: rem(120);
          margin: 0 1rem;
        }
        svg {
          margin-right: rem(8);
        }
      }
    }
  }
  .custom_table {
    .ant-table {
      &-tbody {
        .name {
          color: var(--primaryLightBlue);
          font-weight: 500;
        }
        .phone,
        .description {
          color: var(--modalTitle);
          font-weight: 400;
        }
        .balance {
          font-weight: 500;
          margin: 0;
          color: var(--modalTitle);
          .dollar_icon {
            color: var(--dollarIcon);
          }
          .green {
            color: var(--green);
          }
          .red {
            color: var(--red);
          }
        }
      }
    }
  }
  &_right {
    min-width: rem(385);
    .title {
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--modalTitle);
      margin: rem(20) 0;
      text-align: center;
    }
  }
}

.clients_excel_modal {
  .ant-select {
    &-selector {
      height: rem(56) !important;
      border-color: var(--border) !important;
      border-radius: rem(12) !important;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(24);
      }
    }
  }
}

// 575px
@media (max-width: 575px) {
  .clients {
    flex-direction: column-reverse;
    padding: 0 rem(12) !important;
    &_left {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
      margin-bottom: 2rem;
      &_header {
        margin: 0;
        margin-bottom: 1rem;
        button {
          &.create {
            width: rem(40);
          }
          &.excel {
            width: rem(40);
          }
          svg {
            margin: 0;
          }
        }
      }
    }
    &_right {
      min-width: unset;
      margin-bottom: 1rem;
    }
  }
}
