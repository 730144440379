@import '../../App.scss';

.product_cont {
  .ant-form {
    display: flex;
    .ant-form-item {
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &.name {
        flex: 1;
      }
      &.unit {
        width: rem(120);
        .ant-select {
          width: 100%;
          input {
            height: 100%;
          }
        }
      }
      &.quantity {
        width: rem(140);
      }
      &.price {
        width: rem(160);
      }
      &.overall {
        width: rem(120);
      }
      &.currency_usd {
        width: rem(120);
      }
      &.submit {
        display: flex;
        align-items: flex-end;
        button {
          width: rem(56);
          height: rem(56);
          border-radius: 50%;
          font-weight: 700;
          font-size: rem(24);
          line-height: rem(32);
          text-shadow: none;
        }
      }
      .ant-input-disabled {
        background-color: initial !important;
        color: initial !important;
      }
      .ant-select-disabled {
        .ant-select-selector {
          background-color: initial !important;
          color: initial !important;
        }
      }
    }
    .ant-form-item-label {
      label {
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(24);
        color: var(--modalTitle);
      }
    }
    input,
    textarea {
      border-radius: rem(12);
      font-weight: 400;
      font-size: rem(18);
      line-height: rem(24);
      border-color: var(--border) !important;
    }
    input {
      height: rem(56);
    }
    .ant-select {
      width: rem(96);
      &-selector {
        height: rem(56) !important;
        border-color: var(--border) !important;
        border-radius: rem(12) !important;
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: rem(18);
          line-height: rem(24);
        }
      }
    }
  }
  .ant-form {
    display: flex;
    .ant-form-item {
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &.name {
        flex: 1;
      }
      &.unit {
        width: rem(120);
      }
      &.quantity {
        width: rem(140);
      }
      &.price {
        width: rem(160);
      }
      &.overall {
        width: rem(120);
      }
      &.currency_usd {
        width: rem(120);
      }
      &.submit {
        display: flex;
        align-items: flex-end;
        button {
          width: rem(56);
          height: rem(56);
          border-radius: 50%;
          font-weight: 700;
          font-size: rem(24);
          line-height: rem(32);
          text-shadow: none;
        }
        .action_cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: rem(56);
          height: rem(56);
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: rem(24);
              height: rem(24);
            }
          }
        }
      }
      .ant-input-disabled {
        background-color: initial !important;
        color: initial !important;
      }
    }
    .ant-form-item-label {
      label {
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(24);
        color: var(--modalTitle);
      }
    }
    input,
    textarea {
      border-radius: rem(12);
      font-weight: 400;
      font-size: rem(18);
      line-height: rem(24);
      border-color: var(--border) !important;
    }
    input {
      height: rem(56);
    }
    .ant-select {
      width: rem(96);
      &-selector {
        height: rem(56) !important;
        border-color: var(--border) !important;
        border-radius: rem(12) !important;
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: rem(18);
          line-height: rem(24);
        }
      }
    }
  }
}
