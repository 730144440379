:root {
  --primaryBlue: #1ea5fc;
  --primaryLightBlue: #58aeff;
  --primaryHoverBlue: #f7fbff;
  --border: #eaecf0;
  --white: #ffffff;
  --thead: #fdfdfd;
  --tableTitle: #757d8a;
  --modalTitle: #404d61;
  --dollarIcon: #89919e;
  --green: #08b395;
  --red: #ff5959;
  --deleted: #c3c7ce;
  --cancelBtnBg: #f1f1f1;
}
