@import '../App.scss';

.custom_table {
  .ant-table-wrapper {
    border: rem(1) solid var(--border);
    border-radius: rem(12) rem(12) 0 0;
    overflow: hidden;
    border-bottom: none;
    .ant-table {
      &-thead {
        .ant-table-cell {
          font-weight: 500;
          font-size: rem(16);
          line-height: rem(24);
          color: var(--tableTitle);
          background-color: var(--thead);
          padding: rem(12) rem(24);
        }
        .active {
          color: var(--primaryBlue);
        }
      }
      &-tbody {
        .ant-table-row {
          cursor: pointer;
          &:hover {
            .ant-table-cell {
              background-color: var(--primaryHoverBlue);
            }
          }
        }
        .ant-table-cell {
          padding: rem(12) rem(24);
          font-size: rem(16);
          line-height: rem(24);
        }
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: rem(60);
    background-color: var(--thead);
    border: rem(1) solid var(--border);
    border-top: none;
    border-radius: 0 0 rem(12) rem(12);
    padding: 0 rem(32);
    .ant-pagination {
      display: flex;
      width: 100%;
    }
    .ant-pagination-prev {
      display: flex;
      align-items: center;
      margin-right: auto;
      .prev_btn {
        display: flex;
        align-items: center;
        svg {
          margin-right: rem(4);
        }
        span {
          display: flex;
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(20);
          color: var(--dollarIcon);
          margin-bottom: rem(-2);
        }
      }
    }
    .ant-pagination-next {
      display: flex;
      align-items: center;
      margin-left: auto;
      .next_btn {
        display: flex;
        align-items: center;
        svg {
          margin-left: rem(4);
        }
        span {
          display: flex;
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(20);
          color: var(--dollarIcon);
          // margin-bottom: rem(-2);
        }
      }
    }
    .ant-pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      width: rem(32);
      height: rem(32);
      margin-right: unset;
      border-radius: 50%;
      a {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--dollarIcon);
        min-width: unset;
        height: unset;
        padding: unset;
      }
      &-active {
        background-color: var(--primaryLightBlue);
        a {
          color: var(--white);
        }
      }
    }
  }
}
