@import '../../App.scss';

.settings_modal {
  &_body {
    position: relative;
    .change_password {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--primaryBlue);
      cursor: pointer;
    }
    .fake_password {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      overflow: hidden;
    }
    .ant-input-password {
      border-radius: rem(12);
      border-color: var(--border) !important;
      height: 3.5rem;
      input {
        border-radius: unset !important;
        height: unset;
      }
    }
  }
}
